import Model from './model';

export default class Notification extends Model {
    id = 0;
    isActive = true;
    isSystemNotification = false;
    placeholderTags = [];
    languages = [];

    mapForRequest() {
        return {...this}
    }

    mapResponse(data) {
        return super.mapResponse(data);
    }
}
