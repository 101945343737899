import {get, post, put} from '@/application/api/implementations/app';
import {getPaginated} from '@/application/api/implementations/app/wrapper.js';

export const index = async (clientId, ...args) => getPaginated(`client/${clientId}/notification`, ...args);

export const show = (clientId, notificationId) => get(`client/${clientId}/notification/${notificationId}`);

export const update = (clientId, notification) => put(`client/${clientId}/notification/${notification.id}`, notification);

export const languagesAutocomplete = () => get(`language/autocomplete`);
