<template>
  <div>
    <k-crud-layout :search.sync="searchQuery">
      <template #header>{{ $t('notifications.menuTitle') }}</template>
      <template #view.list>
        <k-crud-table
            ref="table"
            :headers="crudHeaders"
            :index-request="indexRequest"
            :search="searchQuery"
            language-prefix="notifications.crudHeaders"
            resource="notifications"
        >
          <template #item.isActive="{ item: { isActive } }">
            <v-icon v-if="isActive">$enabled</v-icon>
            <v-icon v-else>$disabled</v-icon>
          </template>
          <template #item.name="{ item: { name } }">
            {{ $t(`notifications.notificationTitles.${name}`) }}
          </template>
          <template #actions="{ item }">
            <v-btn icon @click="openUpdateDialog(item)">
              <v-icon>$edit</v-icon>
            </v-btn>
          </template>
        </k-crud-table>
      </template>
    </k-crud-layout>
    <NotificationsForm
        v-model="updateDialog"
        :request="update"
        :title="$t('notifications.update')"
        :values="updateFormValues"
        is-update-form
        @change="$refs.table.reload()"
    />
  </div>
</template>
<script lang="js">
import KCrudTable from '@/components/crud/KCrudTable.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import querySyncMixin from '@/application/mixins/querySyncMixin.js';
import {mapGetters} from 'vuex';
import Notification from '@/application/models/Notification.js';
import NotificationsForm from '@/modules/notifications/views/NotificationsForm.vue';
import {index, show, update} from '@/modules/notifications/api/notifications.js';

export default {
  name: 'NotificationsList',
  mixins: [querySyncMixin],
  components: {
    NotificationsForm,
    KCrudTable,
    KCrudLayout,
  },
  data() {
    return {
      updateDialog: false,
      updateFormValues: new Notification(),
      searchQuery: '',
    };
  },
  computed: {
    ...mapGetters('authorisation', {clientId: 'getCurrentClientId'}),
    crudHeaders() {
      return [
        {
          value: 'isActive',
          language: 'state',
          width: 60,
          align: 'center',
          sortable: false
        },
        {
          value: 'name',
          language: 'notification',
        },
      ];
    },
  },
  methods: {
    reloadTable() {
      this.$refs.table.reload();
    },
    indexRequest(...args) {
      return index(this.clientId);
    },
    async update() {
      await update(this.clientId, this.updateFormValues.mapForRequest());
      this.updateDialog = false;
      this.reloadTable();
    },
    async openUpdateDialog(item) {
      const showRequest = await show(this.clientId, item.id);
      this.updateFormValues = this.updateFormValues.mapResponse(showRequest.data.data);
      this.updateDialog = true;
    },
  },
};
</script>
