<template>
  <k-field-group language-prefix="notifications">
    <k-form-dialog :panels="panels" v-bind="$attrs" v-on="$listeners">
      <template #panel.0>
        <v-row class="fill-height">
          <v-col cols="12">
            <v-row>
              <v-col cols="3">
                <v-tooltip top :disabled="!values.isSystemNotification">
                  <template v-slot:activator="{ on }">
                    <div v-on="on">
                      <KSwitch
                          field="notificationActive"
                          v-model="values.isActive"
                          :disabled="values.isSystemNotification"
                      />
                    </div>
                  </template>
                  <span>{{ $t('notifications.notEditable') }}</span>
                </v-tooltip>
              </v-col>
              <v-col cols="3" offset="6">
                <KSelect
                    class="mt-2"
                    v-model="activeLanguageId"
                    :items="languages"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    hide-details
                    no-label
                />
              </v-col>
            </v-row>
            <form-sheet class="fill-height">
              <v-row no-gutters v-for="(lang, index) in languages" :key="lang.id" class="d-flex flex-column">
                <div v-show="activeLanguageId === lang.id">
                  <v-row v-if="values.placeholderTags.length" class="tags-wrapper mb-2">
                    <v-col cols="12" class="pb-0 text-subtitle-2"> {{ $t('notifications.tags.availableTags') }}</v-col>
                    <v-col cols="12">
                      <span v-for="tag in values.placeholderTags" :key="tag" class="mr-4"
                            :class="{'primary--text': fieldValues.includes(`[ ${tag} ]`) }"
                      >[ {{ tag }} ]</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <KTextField v-model="values.languages[index].subject"
                                  :hint="lang.id !== 1 ? values.languages[0].subject : ''" field="fields.subject"
                                  :required="lang.id === 1"/>
                    </v-col>
                    <v-col cols="12" v-for="(field,key) in values.languages[index].fields" :key="key">
                      <KTextField v-model="values.languages[index].fields[key]"
                                  :hint="lang.id !== 1 ? values.languages[0].fields[key] : ''" :field="`fields.${key}`"
                                  :required="lang.id === 1"/>
                    </v-col>
                  </v-row>
                </div>
              </v-row>
            </form-sheet>
          </v-col>
        </v-row>
      </template>
    </k-form-dialog>
  </k-field-group>
</template>
<script>
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue'
import KFormDialog from '@/components/crud/KFormDialog.vue'
import FormSheet from '@/components/FormSheet.vue'
import KSwitch from '@/components/crud/fields/KSwitch.vue'
import KSelect from '@/components/crud/fields/KSelect.vue'
import KTextField from '@/components/crud/fields/KTextField.vue'
import {languagesAutocomplete} from '@/modules/notifications/api/notifications.js'

export default {
  name: 'NotificationsForm',
  components: {
    KSwitch,
    FormSheet,
    KFieldGroup,
    KFormDialog,
    KSelect,
    KTextField,
  },
  data() {
    return {
      activeLanguageId: 1,
      languages: [],
    }
  },
  computed: {
    panels() {
      return [{name: this.$t('global.defaultTabName')}]
    },
    fieldValues() {
      return [this.values.languages.find(lang => lang.languageId === this.activeLanguageId).subject, Object.values(this.values.languages.find(lang => lang.languageId === this.activeLanguageId).fields)].join()
    }
  },
  async mounted() {
    const result = await languagesAutocomplete()
    this.languages = result.data.data
  },
  watch: {
    activeLanguageId(val) {
      if (!val) this.activeLanguageId = 1
    },
  },
  props: {
    values: {
      type: Object,
    },
  },
}
</script>

<style lang="scss" scoped>
.tags-wrapper {
  background-color: rgba(0, 0, 0, 0.06);
}
</style>
